import CEJ from './media/3-part-harmony.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid2";
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


import './app.scss';
import React, {useState} from 'react';
import SpaceInvaders from './space-invaders/space-invaders';

export default function PersonalProjectsCard(){

    const [isGameVisible, setIsGameVisible] = useState(false);

    return(
        <Card className="intro-projects-card flex" style={{position:"relative"}}>   
            <CardContent className="flex flex-1 offwhite-background">
                    {
                        !isGameVisible &&
                        <div style={{padding:"0", margin:"0"}} className="flex flex-1 flex-col flex-center">
        
                            <Typography variant="h5">Personal Projects</Typography>
                            <Typography variant="subtitle1" style={{paddingTop:'5px'}}>
                                Click on a square to view more about the project
                            </Typography>
                            <div className="flex-1 flex flex-center" style={{paddingTop:'10px'}}>
                                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4, lg: 10, xl: 15 }}>
                                    <Grid size={6}>
                                        <a href="http://www.crosseyedjim.com">
                                            <div className="personal-project-card flex">
                                                    <img src={CEJ} className="personal-project-card"></img>
                                                    <div className="card-foreground"></div>
                                                    <div className="flex flex-col card-foreground-text flex-1">
                                                        <Typography variant="subtitle1">Cross Eyed Jim</Typography>
                                                        <div className="flex-1 flex flex-center">
                                                            <Typography variant="body2">
                                                                Written in Angular, I made this website for my bluegrass band that is currently on hold since having my first child. I may form a new band with a new name.
                                                            </Typography>
                                                        </div>
                                                    </div>
                                            </div>
                                        </a>
                                    </Grid>
                                    <Grid size={6}>
                                        <div className="personal-project-card flex" onClick={()=>setIsGameVisible(true)}>
                                            {/* <img src={CEJ} style={{width:220, height:220}}></img> */}
                                            <div className="card-foreground"></div>
                                            <div className="flex flex-col card-foreground-text flex-1">
                                                <Typography variant="subtitle1">Space Invaders</Typography>
                                                <div className="flex-1 flex flex-center">
                                                    <Typography variant="body2">I was trying to learn react, so I recreated this game with React, NextJs, and Typescript. I don't think react is the best language for games, but it was rewarding to learn.</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        isGameVisible &&
                        <div style={{padding:"0", margin:"0"}} >
                            <SpaceInvaders></SpaceInvaders>
                        </div>
                    }

            </CardContent>
            {
                isGameVisible &&
                <IconButton
                    style={{position:'absolute', right:'0px', top:'0px'}}
                    sx={{ alignSelf: 'center'}}
                    onClick={()=>setIsGameVisible(false)}> 
                    <CloseIcon />
                </IconButton>
            }
        </Card>
    )

    
}