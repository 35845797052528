
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import WorkIcon from '@mui/icons-material/Work';
import HobbyIcon from '@mui/icons-material/SportsEsports';
import SchoolIcon from '@mui/icons-material/School';
import Chip from '@mui/material/Chip';


export interface ResumeItem{
    Title: string;
    Company: string;
    CompanyWebsite?: string | null | undefined;
    Description: string;
    Type: 'Education' | 'Work' | 'Hobby'
    StartDate: Date;
    EndDate?: Date | undefined | null | string;
    Technologies: string[];
}


export default function ResumeCard(){

    const Resume: ResumeItem[] = [
        {
            Title: "Began paternity leave, created this site.",
            Company: "Hobby",
            Description: "Added onto the Space Invaders game I wrote back in 2020, converted it to typescript, hosted it using Render, and registered the domain using cloudflare. I plan on delivering several enhancements to both UI and functionality to this site as time allows.",
            Type: "Hobby",
            StartDate: new Date("10/28/2024"),
            Technologies: ["react","next.js","typescript","onrender", "google domains", "sass", "mui"]
        },
        {
            Title: "Created band website to help with getting shows",
            Company: "Hobby",
            Description: "Whipped up a quick angular page, hosted it with Render.com, and registered it to Google domains. It was a crude project but fun to learn how to register a domain.",
            Type: "Hobby",
            StartDate: new Date("10/28/2023"),
            Technologies: ["angular", "typescript","onrender", "google domains", "sass"]
        },
        {
            Title: "Full Stack Software Developer",
            Company: "Nexuus (sub-company of Equus Software)",
            Description: "Working on new software for Employee Management and Tax Calculations using .net core and entity framework as the backend, and Angular with RXJS as the front end.",
            Type: "Work",
            StartDate: new Date("05/15/2022"),
            EndDate: "Present",
            Technologies: [".net core", "c#", "entity framework", "angular", "rxjs", "typescript", "azure", "ckeditor", "rabbitmq", "sass", "material design", "tailwind"]
        },
        {
            Title: "Covid Hits USA: I teach myself React by making Space Invaders",
            Company: "Hobby",
            Description: "Getting bored using Webforms and JQuery, I knew that React is easily flexible and can fit into legacy software. During my freetime I made a Space Invaders app.",
            Type: "Work",
            StartDate: new Date("02/1/2020"),
            Technologies: ["react", "next.js"]
        },
        {
            Title: "Full Stack Software Developer",
            Company: "Equus Software",
            Description: "Developed and maintained configurable and complex features ranging anywhere from front end portal work, back end tax calculation work, and integration projects for AssignmentPro, the flagship application for the company which calculates international taxes for a relocating employee's payroll.",
            Type: "Work",
            StartDate: new Date("04/15/2015"),
            EndDate: new Date("05/15/2022"),
            Technologies: ["vb.net", "c#", "angular", "jquery", "typescript", "webforms", "vb6", "vba", "react", "redux", "azure", "less css"]
        },
        {
            Title: "Wealth Platform Developer",
            Company: "SEI",
            Description:"Developed web applications and systems used by financial advisors and banks in the US and UK such as Wells Fargo.",
            Type: "Work",
            StartDate: new Date("6/15/2014"),
            EndDate: new Date("3/15/2015"),
            Technologies: ["java", "jsp", "jquery", "jquery", "javascript", "css"]
        },
        {
            Title: "Graduate with a CS Degree",
            Company: "Bloomsburg University",
            Description: "Graduated in 4 years with a degree in Computer Science. Learned various languages such as SQL, Python, Java, JSP, C, C++, etc.  Created a game called 'SuperSnake' which is an android game written in Java. Created a web application that shows Solar Panel Information and display migrated JSON data using HighCharts",
            Type: "Education",
            StartDate: new Date("5/1/2014"),
            Technologies: ["java", "c++", "vb.net", "python", "sql", "javascript", "css"]
        },
        {
            Title: "Junior Application Developer",
            Company: "PPL Services",
            Description: "Worked as an intern on miscellaneous projects using .net technology for a power company.",
            Type: "Work",
            StartDate: new Date("05/15/2013"),
            EndDate: new Date("8/15/2013"),
            Technologies: ["c#", "vb.net", "vbscript", "jquery", "css"]
        },
        {
            Title: "Computer Science Tutor",
            Company: "Bloomsburg University",
            Description: "Worked as a liaison between students and professors, to tutor on an individual or group basis in C++, Java, VB, and other programming subjects.",
            Type: "Work",
            StartDate: new Date("9/15/2012"),
            EndDate: new Date("5/15/2013"),
            Technologies: ["java", "c++", "vb.net", "python", "sql", "javascript", "highcharts", "android development"]
        },
        {
            Title: "Graduate Highschool",
            Company: "Bloomsburg University",
            Description: "In highschool I took AP Computer Science AB where I learned College Level Algorithms and Data Structures in Java. This included the fundamentals of OOP.",
            Type: "Education",
            StartDate: new Date("6/1/2010"),
            Technologies: ["java", "vb.net", "python", "javascript", "php", "html", "css"]
        },
        {
            Title: "Created a Java Applet Copy of 'Arkenoid' or 'Breakout'",
            Company: "Hobby",
            Description: "Source code is long lost, but it has a similar concept to the space invaders game I wrote in react.",
            Type: "Hobby",
            StartDate: new Date("6/1/2008"),
            Technologies: ["java", "javabeans"],
        },
        {
            Title: "Began making Runescape Bots",
            Company: "Hobby",
            Description: "It all started by me wanting to cheat in Runescape. Used color clicking techniques and copied several spiral color searching algorithms from VB6 and rewrote them in VB.net",
            Type: "Work",
            StartDate: new Date("6/1/2006"),
            Technologies: ["vb.net", "vb6", "delphi"]
        }
    ]

    const getTimelineItems = () =>{
        return Resume.map((resumeItem: ResumeItem, i: number)=>{
            return(
                <TimelineItem style={{paddingLeft:0, marginLeft:0}} key={i}>
                    <TimelineOppositeContent sx={{ m: 'auto 0', paddingLeft:0, marginLeft: 0, flex: 0.35 }} 
                        align="right" variant="body2" color="text.secondary">
                        {getDateFormat(resumeItem.StartDate) + (resumeItem.EndDate ? " - " + getDateFormat(resumeItem.EndDate) : "")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                            <TimelineDot>
                                {
                                    (resumeItem.Type === "Work" && <WorkIcon />)
                                    ||
                                    (resumeItem.Type === "Hobby" && <HobbyIcon />)
                                    ||
                                    (resumeItem.Type === "Education" && <SchoolIcon />)
                                }
                            </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator> 
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="subtitle1" component="span">
                            {resumeItem.Title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {resumeItem.Company}
                        </Typography>
                        <Typography variant="body2">{resumeItem.Description}</Typography>
                        {
                            resumeItem.Technologies.map((technology: string, i:number)=>{
                                return(<Chip label={technology} key={i}></Chip>);
                            })
                        }
                    </TimelineContent>
                </TimelineItem>
            )
        });
    }

    const getDateFormat = (date: Date | string):string =>{
        if(date instanceof Date){
            const d = new Date(date);
            return d.toLocaleString('default', { month: 'short' }) + " " + d.getFullYear();
        }
        return date.toString();
    }

    return (
        <Card className="resume-card">
            <CardContent className="offwhite-background flex flex-col flex-center">
                <Typography variant="h5">Software Experience Timeline</Typography>
                <Typography variant="subtitle1" style={{paddingTop:'5px'}}>This includes Work, Education, and Personal Projects</Typography>

                <Timeline position="right"
                    >
                    {getTimelineItems()}
                    </Timeline>             
            </CardContent>
        </Card>
    )

}


