import React from 'react';
import './space-invaders.scss';


interface SpaceInvadersState {
	player: PlayerInterface;
	bulletsPlayer: PositionInterface[];
	bulletsEnemy: PositionInterface[];
	enemies: PositionInterface[];
	enemyForward: boolean;
	rightMostEnemyIndex: number;
	isStarted: boolean;
}

interface PlayerInterface extends PositionInterface{
	xDirection: number;
}

interface PositionInterface{
	key:string;
	xPosition:number;
	yPosition:number;
}

class SpaceInvaders extends React.Component<IProps, SpaceInvadersState>  {

	//game constants:
	frameWidth: number = 500;
	frameHeight: number = 300;
	shipWidth:number  = 30;
	shipGap:number = 15;
	shipHeight:number = 10;
	bulletWidth:number = 2;
	bulletHeight: number = 10;

	constructor(props: IProps){	
		super(props);
		
		this.resetState(true);
	}


	resetState(isFirstTime: boolean = false){
		var enemies:PositionInterface[] = [];
		for (var x = 0; x < 10; x++) {
			for (var y = 0; y < 3; y++) {
				var enemy:PositionInterface = {
					key: x + "," + y,
					xPosition: x * (this.shipWidth + this.shipGap),
					yPosition: y * (this.shipHeight + this.shipGap) + 2
				};
				enemies.push(enemy);
			}
		}
		
		let rightMostIndex = 0;
		for(var i = 0; i < enemies.length; i++){
			if(enemies[i].xPosition > enemies[rightMostIndex].xPosition){
				rightMostIndex = i;
			}
		}

		if(isFirstTime)
			this.state = {
				isStarted: false,
				player: {
					key: "player",
					xDirection: 0,
					xPosition: 200,
					yPosition: this.frameHeight - this.shipHeight - 2
				},
				bulletsPlayer: [],
				bulletsEnemy: [],
				enemies: enemies,
				enemyForward: true,
				rightMostEnemyIndex: rightMostIndex,
			}
		else
			this.setState({
				isStarted: false,
				player: {
					key: "player",
					xDirection: 0,
					xPosition: 200,
					yPosition: this.frameHeight - this.shipHeight - 2
				},
				bulletsPlayer: [],
				bulletsEnemy: [],
				enemies: enemies,
				enemyForward: true,
				rightMostEnemyIndex: rightMostIndex,
			});
	}


	interval: NodeJS.Timeout | undefined;
	componentDidMount(){
		window.addEventListener("keydown", this.handleKeyDown);
	}
	
	componentWillUnmount() {
		if(this.interval)
			clearInterval(this.interval);
		window.removeEventListener("keydown", this.handleKeyDown);
	}
	
	tick = () => {
		var newPlayerState:PlayerInterface = this.movePlayer();
		var newBulletState:PositionInterface[] = this.moveBulletsPlayer();
		var newEnemiesState:PositionInterface[] =  this.moveEnemies();
		var newEnemyBulletsState: PositionInterface[] = this.handleEnemyBullets(newEnemiesState);
		
		var postCollisionEnemiesAndBulletsDTO = this.handleCollisions(newEnemiesState, newBulletState);
		
		this.setState(
			{
				player:{
					key: "player",
					xDirection: newPlayerState.xDirection,
					xPosition: newPlayerState.xPosition,
					yPosition: this.state.player.yPosition
				},
				bulletsPlayer : postCollisionEnemiesAndBulletsDTO.bullets,
				bulletsEnemy : newEnemyBulletsState,
				enemies: postCollisionEnemiesAndBulletsDTO.enemies,
				rightMostEnemyIndex: postCollisionEnemiesAndBulletsDTO.rightMostIndex
			});
	}

	handleEnemyBullets = (newEnemiesState: PositionInterface[]): PositionInterface[] =>{
		let newEnemyBulletsState:PositionInterface[] = [];

		newEnemiesState?.forEach(enemy=>{
			if(this.getRandomInt(500) ===250){
				//1 in 500 chance.
				const newBullet: PositionInterface = {
					key: Math.random().toString(),
					xPosition: Math.round(enemy.xPosition + (this.shipWidth / 2)),
					yPosition: enemy.yPosition + this.shipHeight
				}
				newEnemyBulletsState.push(newBullet);
			}
		});
		if(this.state.bulletsEnemy){
			this.state.bulletsEnemy.forEach(enemyBullet => {
				
				if(enemyBullet.yPosition + this.bulletHeight > this.state.player.yPosition
					&&
					enemyBullet.xPosition < this.state.player.xPosition + this.shipWidth
					&&
					enemyBullet.xPosition > this.state.player.xPosition
					){
					//collision detected.
					this.endGame();
				}


				if(enemyBullet.yPosition + this.bulletHeight < this.frameHeight){
					enemyBullet.yPosition = enemyBullet.yPosition + 4;
					newEnemyBulletsState.push(enemyBullet);
				}
			})
		}

		return newEnemyBulletsState;
	}

	endGame = () =>{
		this.setState({
			isStarted:false
		});
		clearInterval(this.interval);
	}
	
	movePlayer = (): PlayerInterface => {
		var newPlayerState:PlayerInterface = {
			xPosition: 0,
			xDirection: 0,
			yPosition: this.state.player.yPosition
		};
		if (this.state.player.xPosition <= 0 && this.state.player.xDirection === -1){
			newPlayerState.xDirection = 0;
			newPlayerState.xPosition = 0
		}else if(this.state.player.xPosition + this.shipWidth >= this.frameWidth && this.state.player.xDirection === 1){
			newPlayerState.xDirection = 0;
			newPlayerState.xPosition = this.frameWidth - this.shipWidth;
		}
		else{
			newPlayerState.xPosition = this.state.player.xPosition + (this.state.player.xDirection * 2);
			newPlayerState.xDirection = this.state.player.xDirection;
		}
		return newPlayerState;
	}
	
	moveBulletsPlayer = (): PositionInterface[] =>{
		if(this.state.bulletsPlayer?.length > 0){
			var newBullets: PositionInterface[] = [];
			this.state.bulletsPlayer.forEach((bullet) => {
				if (bullet.yPosition > 0){
					newBullets.push(
					{
						xPosition: bullet.xPosition,
						yPosition: bullet.yPosition - 4
					})
				}
			});
			return newBullets;
		}
		return this.state.bulletsPlayer;
	}
	
	moveEnemies = (): PositionInterface[] =>{
		var newEnemies: PositionInterface[] = [];

		if(this.state.enemyForward === true){
			if(this.state.enemies[this.state.rightMostEnemyIndex].xPosition + this.shipWidth < this.frameWidth){
				this.state.enemies.forEach(enemy =>{
					enemy.xPosition = enemy.xPosition + 1;
					newEnemies.push(enemy);
				});
			}else{
				this.state.enemies.forEach(enemy =>{
					enemy.xPosition = enemy.xPosition - 1;
					enemy.yPosition = enemy.yPosition + 10
					newEnemies.push(enemy);
				});
				this.setState({enemyForward: false});
			}
		}else{
			if(this.state.enemies[0].xPosition > 0){
				this.state.enemies.forEach(enemy =>{
					enemy.xPosition = enemy.xPosition - 1;
					newEnemies.push(enemy);
				});
			}else{
				this.state.enemies.forEach(enemy =>{
					enemy.xPosition = enemy.xPosition + 1;
					enemy.yPosition = enemy.yPosition + 10
					newEnemies.push(enemy);
				});
				this.setState({enemyForward: true});
			}
		}

		return newEnemies;
	}
	
	handleCollisions = (enemies: PositionInterface[], bullets: PositionInterface[]) => {
		for(var i = 0; i < enemies.length; i++){
			var enemy = enemies[i];
			for(var z = 0; z < bullets.length; z++){
				var bullet = bullets[z];
				if(
					bullet.yPosition <= enemy.yPosition + 10 
					&&
					bullet.xPosition + this.bulletWidth > enemy.xPosition
					&& 
					bullet.xPosition < enemy.xPosition + this.shipWidth
				){
					//A collision has happened. Splice = remove.
					enemies.splice(i,1);
					bullets.splice(z,1);
					

					let rightMostIndex = 0;
					for(var j = 0; j < enemies.length; j++){
						if(enemies[j].xPosition > enemies[rightMostIndex].xPosition){
							rightMostIndex = j;
						}
					}
					if (enemies.length === 0){
						clearInterval(this.interval);
						this.endGame();
					}
					
					return {bullets: bullets, enemies: enemies, rightMostIndex: rightMostIndex}
				}
			}
		}
		return {bullets: bullets, enemies: enemies, rightMostIndex: this.state.rightMostEnemyIndex};
	}

	
	handleKeyDown = (event: KeyboardEvent) => {
		if(!this.state.isStarted){
			this.resetState();
			this.setState({
				isStarted: true
			})
			this.interval = setInterval(this.tick, 10);
			return;
		}

		if ( event.key === "a" || event.key ==='A') {
			this.setState({
				player: {
					key: "player",
					xDirection:-1,
					xPosition: this.state.player.xPosition,
					yPosition: this.state.player.yPosition
				}
			});
		}
		else if ( event.key === "d" || event.key === "D") {
			this.setState({
				player:  {
					key: "player",
					xDirection:1,
					xPosition: this.state.player.xPosition,
					yPosition: this.state.player.yPosition
				}
			});
		}else if ( event.key === "w" || event.key === "W") {
			var bullets = this.state.bulletsPlayer;
			bullets.push(
				{
					key: Math.random().toString(),
					xPosition: this.state.player.xPosition + 12,
					yPosition: this.state.player.yPosition
				}
			)
			this.setState({
				bulletsPlayer:  bullets
			});
			
		}
	}
	
	getRandomInt(max: number) {
		return Math.floor(Math.random() * max);
	}

    render(){
		const isStarted = this.state.isStarted;
		return(
			<div className="main-frame" style = {{
					width: this.frameWidth + 'px', 
					height: this.frameHeight + 'px'
					}}
				>
				{
					this.state.enemies.map((enemy) => (
						<Enemy key={enemy.key} xPosition = {enemy.xPosition} yPosition = {enemy.yPosition} width= {this.shipWidth} 
							height={this.shipHeight}/>
					))
				}
				{
					this.state.bulletsPlayer.map((bullet) => (
						<Bullet key={bullet.key} xPosition = {bullet.xPosition} yPosition = {bullet.yPosition} width = {this.bulletWidth} height = {this.bulletHeight}/>
					))
				}		
				{
					this.state.bulletsEnemy.map((bullet)=> (
						<span className = 'bullet' key={bullet.key} style = 
						{{
							left: bullet.xPosition + 'px', 
							top: bullet.yPosition + 'px',
							width: this.bulletWidth + 'px',
							height: this.bulletHeight + 'px'
						}}></span>
					))
				}
				
				<Player xPosition = {this.state.player.xPosition} yPosition = {this.state.player.yPosition} width={this.shipWidth}
					height={this.shipHeight}/>

				{!isStarted ? <SplashScreen/>: ''}

			</div>
		)
    }
}

interface LocationSizeProps{
	xPosition?: number;
	yPosition?: number;
	width?: number;
	height?: number;
}

class Enemy extends React.Component<LocationSizeProps>{
	render(){
		 return(
			<span className='square enemy' style = 
				{{
					left: this.props.xPosition + 'px', 
					top:this.props.yPosition + 'px', 
					width: this.props.width + 'px',
					height: this.props.height + 'px'
				}}/>
		 );
	}
}

class Player extends React.Component<LocationSizeProps>{
	render(){
		return(
			<span className = 'square player' style = 
				{{
					left: this.props.xPosition + 'px', 
					top:this.props.yPosition + 'px', 
					width: this.props.width + 'px',
					height: this.props.height + 'px'
				}}/>
		)
	}
}

class Bullet extends React.Component<LocationSizeProps>{
	render(){
		return(
			<span className = 'bullet' style = 
			{{
				left: this.props.xPosition + 'px', 
				top:this.props.yPosition + 'px',
				width: this.props.width + 'px',
				height: this.props.height + 'px'
			}}></span>
		)
	}
}

class SplashScreen extends React.Component{
	render(){ 
		return(
			<div className="splash-screen">
				<h2>React Space Invaders by Jake Weber</h2>
				<h2>Desktop use only (for now)</h2>
				<h2>a = Left; d = Right; w = Shoot</h2>
				<h2>Press any key to begin.</h2>
			</div>
		)
	}
}


export default SpaceInvaders