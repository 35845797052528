import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {teal} from '@mui/material/colors';
import PersonalProjectsCard from './personal-projects';
import ResumeCard from './resume';
import CardMedia from '@mui/material/CardMedia';
import Profile from './media/blurred-code.jpg';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './app.scss';

export default function App() {
	React.useEffect(()  => {
		document.body.style.padding = '0';
		document.body.style.margin = '0';
		document.body.style.backgroundColor = teal[200];
	});


	return (
		<div>
			<AppBar position="sticky" sx={{ bgcolor: teal[500]}}>
				<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography variant="h6" noWrap component="a" sx={{
						mr: 2,
						display: { xs: 'none', md: 'flex' },
						fontFamily: 'monospace',
						fontWeight: 700,
						letterSpacing: '.3rem',
						color: 'inherit',
						textDecoration: 'none',
					}}
					>
						Jake Weber
					</Typography>
					<Typography variant="h5" noWrap component="a"
						href="#app-bar-with-responsive-menu"
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
						>
					Jake Weber
					</Typography>
				</Toolbar>
				</Container>
			</AppBar>

			<div className ="w-full flex flex-col flex-center">
				<Card className="intro-projects-card">
						<CardMedia sx={{ height: 132 }} image={Profile}> 
							<div className="flex flex-center flex-col h-full">
								<Typography variant="h4" style={{color: teal[50]}}>Jake Weber</Typography>
								<Typography variant="h6" style={{color: teal[50]}}>Senior Full Stack Software Engineer</Typography>
							</div>
						</CardMedia>
						<CardContent className="offwhite-background" style={{position:"relative"}}>
							<Typography variant="subtitle1">Welcome to my Online Resume!</Typography>
							<Typography variant="body1">I am a software developer who loves both debugging through legacy software to find the root cause of any issues, and creating new software. My strengths include the ability to discern whether to establish workarounds when necessary, communicating transparently and openly with a team, and learning new technologies.</Typography>
							<br/>
							<Typography variant="body1"><a href="mailto:jakeweber92@gmail.com">jakeweber92@gmail.com</a></Typography>
							<a style={{position:"absolute", right:"15px", bottom:"15px"}} href="https://www.linkedin.com/in/jacob-weber-5908b287/"><LinkedInIcon></LinkedInIcon></a>
						</CardContent>
				</Card>
				<PersonalProjectsCard></PersonalProjectsCard>
				<ResumeCard></ResumeCard>
			</div>
		</div>
	);
}